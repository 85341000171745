export let theStack = (xMsg, channelOptionData) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {},
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 65,
                end: 85
            },
            {
                type: 'inside',
                realtime: true,
                start: 65,
                end: 85
            }
        ],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                // rotate:40,
            },
            data: xMsg
        },

        axisLabel: {
            formatter: function (value) {
                var res = value;
                if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                }
                return res;
            }
        },
        yAxis: {

            type: 'value'
        },
        series: channelOptionData
    };
}