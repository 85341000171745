<template>
  <div class="box">
    <div class="channel" style="border-radius: 8px">
      <div class="title">
        <div class="">
          <span style="font-size: 18px; font-weight: bold; color: black"
            >渠道统计</span
          >
          &nbsp;
          <span>单位:元</span>
        </div>
        <div class="">
          <a-button
            style="color: #fb4702; font-weight: bold"
            @click="showDialog('cargoNum')"
            >渠道选择</a-button
          >
          &nbsp;
          <a-range-picker
            @change="onChange"
            style="width: 200px"
            format="YYYY-MM-DD HH:mm:ss"
            v-model="channelTime"
          />
        </div>
      </div>
      <div id="first"></div>
    </div>
    <div style="height: 100px"></div>
    <a-modal
      :title="dialog.title"
      :visible="dialog.show"
      @ok="handleOk"
      @cancel="dialog.show = false"
    >
      <div class="channel_choose">
        <div class="left">
          <button
            v-for="(item, index) in channelLists"
            :key="index"
            :class="{ my_button: true, active_button: item.active == true }"
            @click="chooseType(item)"
          >
            {{ item.cate_name }}
          </button>
        </div>
        <div class="right">
          <button
            v-for="(item, index) in chooseChannelLists"
            :key="index"
            :class="{ my_button: true, active_button: item.active == true }"
            @click="chooseId(item)"
          >
            {{ item.channel_name }}
          </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { theStack } from "./option";
import moment from "moment";
import { timelinessChannel, channelList } from "@/api/DataStatistics";
export default {
  name: "Limitation",
  data() {
    return {
      channelLists: [],
      chooseChannelLists: [],
      chooseIdArr: [],
      channelTime: [
        moment().subtract(3, "months"),
        moment(new Date().getTime()),
      ],

      moment,
      dialog: {
        show: false,
        title: "渠道选择",
      },
      screenWidth: null,
      myChart: {
        channel: null,
      },
      form: {
        channelIds: [],
      },

      channelOptionData: [],
      xMsg: [],
      cargoNumConfig: {},
    };
  },
  mounted() {
    this.showCharts();
    window.onresize = () => {
      this.myChart.channel.resize();
    };
  },
  created() {
    channelList().then((res) => {
      let obj = {
        cate_name: "全部",
        channels: [],
        active: true,
      };
      res.data.forEach((ele) => {
        obj.channels = obj.channels.concat(ele.channels);
      });
      this.chooseChannelLists = obj.channels;
      res.data.unshift(obj);
      this.channelLists = res.data;
    });
    this.getData();
  },
  methods: {
    showDialog(type) {
      this.dialog.show = true;
      this.dialog.type = type;
      this.chooseIdArr = [];
      switch (type) {
        case "cargoNum":
          this.chooseIdArr = this.form.channelIds;
          break;
        case "cargoMonth":
          this.chooseIdArr = this.cargoMonthChannelIds;
          break;
        default:
          this.chooseIdArr = [];
          break;
      }
      for (let i of this.chooseChannelLists) {
        i.active = false;
        if (this.chooseIdArr.includes(i.channel_id)) i.active = true;
      }
    },
    chooseId(item) {
      this.chooseIdArr.includes(item.channel_id)
        ? this.chooseIdArr.splice(this.chooseIdArr.indexOf(item.channel_id), 1)
        : this.chooseIdArr.push(item.channel_id);
      for (let i of this.chooseChannelLists) {
        i.active = false;
        if (this.chooseIdArr.includes(i.channel_id)) {
          i.active = true;
        }
      }
      this.chooseChannelLists.sort();
    },
    chooseType(item) {
      for (let i of this.channelLists) {
        i.active = false;
        if (i.cate_name == item.cate_name) {
          i.active = true;
          this.chooseChannelLists = i.channels;
        }
      }
      this.channelLists.sort();
    },
    onChange(date, dateString) {
      this.channelTime = date;
      this.getData();
    },
    handleOk() {
      if (this.dialog.type == "cargoNum") {
        this.form.channelIds = this.chooseIdArr;
        this.form.timeStart = moment(this.channelTime[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.form.timeEnd = moment(this.channelTime[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.getData();
      }
    },
    getData() {
      timelinessChannel(this.form).then((res) => {
        let channelOptionData = [];
        let xMsg = [];
        let data = res.data.filter((ele) => ele.timeliness.length > 0);
        let channelOptMap = {};
        for (let k = 0; k < data.length; k++) {
          const target = data[k];
          for (let i of target.timeliness) {
            if (channelOptMap[i.section_id])
              channelOptMap[i.section_id].push(i);
            else channelOptMap[i.section_id] = [];
          }
        }
        for (let key in channelOptMap) {
          const targetAll = channelOptMap[key];
          const target = targetAll[0] || {};
          channelOptionData.push({
            id: key,
            name: target.start + "-" + target.end + "天",
            type: "bar",
            stack: "total",
            emphasis: {
              focus: "series",
            },
            data: [],
            barMaxWidth: "40",
            itemStyle: {
              //自定义颜色
              normal: {
                color: target.color,
              },
            },
            data: [],
          });
        }
        for (let i = 0; i < data.length; i++) {
          const target = data[i];
          xMsg.push(target.channel_name);
          for (let j = 0; j < target.timeliness.length; j++) {
            const timeTarget = target.timeliness[j];
            const source =
              channelOptionData.find((t) => t.id == timeTarget.section_id) ||
              {};
            source.data[i] = timeTarget.count;
          }
          const allTime = target.timeliness.map((t) => t.section_id);
          channelOptionData.forEach((ele) => {
            if (!allTime.includes(Number(ele.id))) ele.data[i] = 0;
          });
        }
        this.channelOptionData = channelOptionData;
        this.xMsg = xMsg;
        this.showCharts();
        this.dialog.show = false;
      });
    },
    showCharts() {
      this.myChart.channel = this.$echarts.init(
        document.getElementById("first")
      );
      this.myChart.channel.setOption(
        theStack(this.xMsg, this.channelOptionData)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.channel_choose {
  display: flex;

  .left {
    width: 80px;
    border-right: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 15px;
    padding-right: 15px;

    .my_button {
      background: #f4f9fd;
      color: #808282;
      border: none;
      width: 60px;
      border-radius: 4px;
      margin-top: 15px;
      text-align: center;
      border: 1px solid transparent;
    }

    .my_button:hover {
      color: #fa4800;
    }

    .active_button {
      background: #fffbfa;
      color: #fe822f;
      border: 1px solid #ffe1cd;
    }
  }

  .right {
    padding: 0 15px;
    height: 250px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .my_button {
      background: #f4f9fd;
      color: #808282;
      border: none;
      border-radius: 4px;
      margin-top: 15px;
      height: 25px;
      text-align: center;
      border: 1px solid transparent;
    }

    .my_button:hover {
      color: #fa4800;
    }

    .active_button {
      background: #fffbfa;
      color: #fe822f;
      border: 1px solid #ffe1cd;
    }

    button {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}

.box {
  background: #f0f2f5;

  .btns {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  .channel {
    background: #ffffff;
    width: 100%;
    padding: 10px;
    margin-top: 15px;

    #first {
      width: 100%;
      min-height: 500px;
    }

    #fourth {
      width: 100%;
      min-height: 500px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
  }
}

/deep/.ant-modal-body {
  padding: 0 12px;
}
</style>
